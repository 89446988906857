// eslint-disable-next-line no-restricted-imports
// import Router from 'next/router'
import { TokenPayload } from '../type/TokenPayload'
import cookies from 'next-cookies'
import { NextPageContext } from 'next'
import { X_AUTH_TOKEN } from './Client'
import { readToken } from './JWTToken'
import { getCookie as getClientCookie, COOKIES_NAME_LANGUAGE, COOKIES_NAME_CANDIDATE_ONBOADING } from './Cookies'
import { EnumUserType } from '../Enum/UserTypeEnum'

type UtilsFunctionParams = {
  token: string
  jwt: TokenPayload
  navigateTo: (path: string, options?: { clearToken: boolean }) => void
  lang: LanguageEnum
  getCookies: (key: string) => string
}

const createUtilFuction = (checkFuction: (params: UtilsFunctionParams) => void) => {
  return (Page) => {
    const passing = Page.getInitialProps
    Page.getInitialProps = (ctx: NextPageContext) => {
      if (ctx && ctx.req) {
        const { req } = ctx
        const getCookies = (key) => req.headers.cookie && cookies(ctx)[key]
        const token = getCookies(X_AUTH_TOKEN)
        const navigateTo = (path: string, options?: { clearToken: boolean }) => {
          const { clearToken } = options || { clearToken: false }
          if (clearToken) {
            ctx.res.writeHead(302, {
              Location: path,
              'Set-Cookie': `${X_AUTH_TOKEN}=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`
            })
          } else {
            ctx.res.writeHead(302, { Location: path })
          }

          ctx.res.end()
        }
        const jwt = readToken(token)
        const userLanguage = getCookies(COOKIES_NAME_LANGUAGE) as LanguageEnum
        checkFuction({ token, navigateTo, jwt, lang: userLanguage, getCookies })
      } else {
        const token = getClientCookie(X_AUTH_TOKEN)
        const navigateTo = (path: string) => {
          // Router.push(path)
          window.location.href = path
        }
        const jwt = readToken(token)
        const userLanguage = getClientCookie(COOKIES_NAME_LANGUAGE) as LanguageEnum
        checkFuction({ token, navigateTo, jwt, lang: userLanguage, getCookies: getClientCookie })
      }
      return passing ? passing(ctx) : {}
    }
    return Page
  }
}

export const createPageUtils = ({
  getPageIndex,
  loginPage,
  onBoadingPage
}: {
  getPageIndex: (params: { jwt: TokenPayload; lang: LanguageEnum }) => string
  loginPage: string
  onBoadingPage?: string
}) => {
  const anonymousAccess = createUtilFuction(({ token, navigateTo, jwt, lang }) => {
    if (token) {
      navigateTo(getPageIndex({ jwt, lang }))
    }
  })
  const privateAcess = createUtilFuction(({ token, navigateTo, jwt }) => {
    const tokenInvalid = token && jwt && jwt.user_type && Object.values(EnumUserType).includes(jwt.user_type)
    if (!tokenInvalid) {
      navigateTo(loginPage, { clearToken: true })
    }
  })
  const checkOnboarding = createUtilFuction(({ token, navigateTo, jwt, getCookies }) => {
    const tokenInvalid = token && jwt && jwt.user_type && Object.values(EnumUserType).includes(jwt.user_type)
    if (onBoadingPage && tokenInvalid && getCookies(COOKIES_NAME_CANDIDATE_ONBOADING)) {
      navigateTo(onBoadingPage)
    }
  })
  return {
    anonymousAccess,
    privateAcess,
    checkOnboarding
  }
}
