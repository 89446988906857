import { createPageUtils } from '@freec/core/lib/utilities/PageUtilsFactory'
import { LINK_URL_CANDIDATE_HOME, LINK_URL_LOGIN, LINK_URL_ONBOARDING_CANDIDATE } from './URL'
import { translateRouterHref } from '@freec/corelib/components/share/AppLink'
import { NextPageContext } from 'next'
import querystring from 'querystring'
import cookies from 'next-cookies'
import { X_AUTH_TOKEN } from '@freec/corelib/utilities/Client'

export const { anonymousAccess, privateAcess, checkOnboarding } = createPageUtils({
  loginPage: LINK_URL_LOGIN,
  onBoadingPage: LINK_URL_ONBOARDING_CANDIDATE,
  getPageIndex: ({ lang }) => {
    return translateRouterHref(LINK_URL_CANDIDATE_HOME, lang)
  }
})

export const pageCacheControl = (Compoent, options: { maxAge?: number; ignoreQuery?: string[]; revalidate?: boolean }) => {
  const passing = Compoent.getInitialProps
  const age = options?.maxAge || 7200 // 2h
  const finalIgnoreList = ['amp'] || options?.ignoreQuery
  Compoent.getInitialProps = (ctx: NextPageContext) => {
    if (ctx.req) {
      const getCookies = (key) => ctx.req.headers.cookie && cookies(ctx)[key]
      const token = getCookies(X_AUTH_TOKEN)
      if (!token) {
        const searchString = ctx.req.url.match(/\?(.+$)/)
        if (searchString && searchString[1]) {
          const query = querystring.parse(searchString[1]) || {}
          const listQueryName = Object.keys(query)
          if (!finalIgnoreList.find((inore) => listQueryName.includes(inore))) {
            if (options.revalidate) {
              ctx.res.setHeader('cache-control', `s-maxage=0, stale-while-revalidate`)
            } else {
              // stale-while-revalidate
              ctx.res.setHeader('cache-control', `s-maxage=${age}, must-revalidate`)
            }
          }
        } else {
          if (options.revalidate) {
            ctx.res.setHeader('cache-control', `s-maxage=0, stale-while-revalidate`)
          } else {
            // stale-while-revalidate
            ctx.res.setHeader('cache-control', `s-maxage=${age}, must-revalidate`)
          }
        }
      }
    }

    return passing ? passing(ctx) : {}
  }
  return Compoent
}
