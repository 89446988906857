import Head from 'next/head'
import React from 'react'
import { EliminateQueryCanonical } from '@freec/core/lib/components/SEOMeta/CanonicalMeta'
import { FreeCNextPage } from '@freec/corelib/type/Common'
import env from 'utilities/env'
import LoginWrapper from 'wrapper/login'
import { anonymousAccess } from 'utilities/PageUtilities'
import PublicPageMeta from '@freec/corelib/components/SEOMeta/PublicPageMeta'
import { THUMBNAIL_SHARE_JOB_SOCIAL } from 'utilities/ResourceConstant'
import withPageWrapper from 'layout/hoc/withPageWrapper'

const LoginPage: FreeCNextPage = () => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <EliminateQueryCanonical />
      <PublicPageMeta
        title="Đăng nhập tài khoản tại freeC - freeC.asia"
        description="Đăng nhập để theo dõi các bài đăng, hồ sơ, phỏng vấn và hơn thế nữa. Đăng nhập ngay"
        image={`${env.PUBLIC_APP_DOMAIN}${THUMBNAIL_SHARE_JOB_SOCIAL}`}
      />
      <LoginWrapper />
    </>
  )
}

export default withPageWrapper(anonymousAccess(LoginPage))
