/* eslint-disable no-console */
import { useStoreTokenAndFetchUser } from '@freec/core/lib/hooks/CurrentUserHooks'
import { useAppRouter } from '@freec/core/lib/hooks/UseAppRouter'
import { useTranslation } from '@freec/core/lib/hooks/UserTranslation'
import { handleErrorAPIFromRequest, LIST_URL } from '@freec/core/lib/utilities/CatchApiError'
import { openNotificationWithIcon } from '@freec/core/lib/utilities/Notification'
import React, { ReactElement, useState } from 'react'
import LoginView from './components/LoginView'
import { toStringFromQuery } from '@freec/corelib/utilities/common'
import { LINK_URL_TOKEN_NOT_FOUND } from 'utilities/URL'
import { submitLoginAPI, submitLoginWithSocialAPI, submitResendEmailAPI } from 'service/api/login.api'
import { useMixpanel } from '@freec/corelib/utilities/Mixpanel'
import { useVerifyDataState, useVerifyMessageState } from '@freec/core/lib/hooks/useVerifyMessageHook'
import { useDimention } from '@freec/corelib/hooks/UseDimention'
import { useRegisterServiceWorker } from 'modules/notifications/firebase'
import useLinkedinLogin from './hooks/useLinkedinLogin'
import { getlinkedinCode } from 'utilities/SocialUtilsFunction'

const LoginWrapper = () => {
  const [verifyData, setVerifyData] = useVerifyDataState()
  const [verifyMessage, setVerifyMessage] = useVerifyMessageState()
  const { translate } = useTranslation()
  const router = useAppRouter()
  const { fromProduct } = useDimention()
  const [nonFieldError, setNoneFieldError] = useState<string | ReactElement>()
  const [isVerify, setIsVerify] = useState(false)
  const [loginIn, setLoginIn] = useState(false)

  const message = verifyMessage?.message
  const fallBackUrl = router.query.redirect
  const storeTokenAndFetchUser = useStoreTokenAndFetchUser()
  const Mixpanel = useMixpanel()
  const registerServiceWorker = useRegisterServiceWorker()
  /* ----------- API ----------- */

  const onFinish = (values) => {
    setLoginIn(true)
    return submitLoginAPI(values)
      .then((response) => {
        return storeTokenAndFetchUser(response.data.token).then(() => {
          registerServiceWorker()
          window.location.href = fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : '/'
        })
      })
      .catch((error) => {
        handleErrorAPIFromRequest({
          error,
          router,
          page: LIST_URL.LOGIN,
          callbackAction: (errorText) => setNoneFieldError(errorText),
          callbackType: (type) => {
            if (['NOT_VERIFIED_ACCOUNT', 'NOT_VERIFIED_USER'].includes(type)) {
              const response = error?.response || {}
              const { data } = response
              setVerifyMessage({ message: translate('notification:you_have_not_verified_yet') })
              setVerifyData({
                email: values.email.toLowerCase(),
                user_id: data?.messages?.user_id
              })
              setIsVerify(true)
              localStorage.setItem('AUTHENTICATION_TOKEN', '')
            }
          }
        })
      })
      .finally(() => {
        setLoginIn(false)
      })
  }

  const onSendVerify = (email, user_id) => {
    return submitResendEmailAPI({ email, user_id })
      .then((response) => {
        if (response.status && response.status !== 200) {
          setIsVerify(false)
          setVerifyMessage({
            message: translate('notification:you_have_verified_your_email')
          })
          router.push('/login')
        }
      })
      .catch((error) => {
        handleErrorAPIFromRequest({
          error,
          page: LIST_URL.REGISTER_RESEND,
          callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription),
          callbackType: (type) => {
            if (type === 'INVALID_TOKEN') {
              router.push(LINK_URL_TOKEN_NOT_FOUND)
            }
          }
        })
        throw error
      })
  }

  const socialLogin = (token, provider) => {
    return submitLoginWithSocialAPI({ token, provider, from_product: fromProduct })
      .then((response) => {
        // add tracking
        registerServiceWorker()
        Mixpanel.identifyByToken(response.data.token)
        Mixpanel.trackLogin(Mixpanel.LoginMethod.SOCIAL)
        return storeTokenAndFetchUser(response.data.token).then(
          () => (window.location.href = fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : '/')
        )
      })
      .catch((error) => {
        console.log(error)
        handleErrorAPIFromRequest({
          error,
          page: LIST_URL.LOGIN_WITH_SOCIAL,
          callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription)
        })
      })
  }

  /* ----------- FUNCTION HANDLER ----------- */

  const loginFacebook = (data) => socialLogin(data?.tokenDetail?.accessToken, 0)

  const responseGoogle = (response) => {
    // console.log({ response })
    if (response.accessToken) {
      return socialLogin(response.accessToken, 1)
    } else {
      console.error('cannot retrieve access token', response)
      return Promise.reject('cannot retrieve access token')
    }
  }

  const { linkedInLogin } = useLinkedinLogin({
    onLoginCode: (code) => {
      return socialLogin(getlinkedinCode(code), 4)
    }
  })

  const googleSocialErrorHandler = (err) => {
    console.error('google error', err)
  }

  /* ----------- RENDER ----------- */

  return (
    <>
      <LoginView
        verifyData={verifyData}
        nonFieldError={nonFieldError}
        isVerify={isVerify}
        loginIn={loginIn}
        message={message}
        onFinish={onFinish}
        onSendVerify={onSendVerify}
        loginFacebook={loginFacebook}
        responseGoogle={responseGoogle}
        googleSocialErrorHandler={googleSocialErrorHandler}
        linkedInLogin={linkedInLogin}
      />
    </>
  )
}

export default LoginWrapper
