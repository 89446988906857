import {
  API_URL_CANDIDATES_LOGIN_SOCIAL,
  API_URL_LOGIN,
  API_URL_RESEND_EMAIL,
  API_URL_CANDIDATES_GOOGLE_ONE_TAP
} from 'utilities/APIConstant'
import Client from 'utilities/Client'

export const submitLoginAPI = (values) => {
  return Client.post<any>(API_URL_LOGIN, { data: { email: values.email.toLowerCase(), password: values.password }, preventRedirect: true })
}

export const submitResendEmailAPI = ({ email, user_id }) => {
  return Client.post(API_URL_RESEND_EMAIL, {
    data: {
      user_id: user_id,
      email: email
    }
  })
}

export const submitLoginWithSocialAPI = ({ token, provider, from_product }) => {
  return Client.post<any>(API_URL_CANDIDATES_LOGIN_SOCIAL, {
    sendUTM: true,
    data: { provider, token, from_product }
  })
}

export const submitAuthGoogleOneTapAPI = ({ token, from_product }) => {
  return Client.post<any>(API_URL_CANDIDATES_GOOGLE_ONE_TAP, {
    data: { from_product, token }
  })
}
