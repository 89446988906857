// import { Trans } from 'react-i18next'
import { ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import Image from 'next/image'
import React, { FC, ReactElement } from 'react'
import { FacebookProvider, Login } from 'react-facebook-sdk'
import { GoogleLogin } from 'react-google-login'
import { useTranslation } from '@freec/core/lib/hooks/UserTranslation'
import { SocialStyle } from '@freec/core/lib/style/styled/antd/Button.style'
import { Row, Col } from '@freec/core/lib/components/share/ANTD/Grid'
import { AppLink } from '@freec/core/lib/components/share/AppLink'
import { Form } from '@freec/core/lib/components/share/ANTD/Form'
import { Button } from '@freec/core/lib/components/share/ANTD/Button'
import { Input } from '@freec/core/lib/components/share/ANTD/Input'
import { Divider } from '@freec/core/lib/components/share/ANTD/Divider'
import { TRACKING_ID_LOGIN_GOOGLE, TRACKING_ID_LOGIN_LOGIN } from '@freec/core/lib/utilities/TrackingIDConstant'
import ChangeLanguageDropdown from '@freec/core/lib/components/ChangeLanguageDropdown'
import Footer from '@freec/core/lib/layout/Footer'
import { RegisterStyle } from '@freec/core/lib/style/styled/RegisterStyle.style'
import { ANTDInputStyle } from '@freec/core/lib/style/base/antd-input-style'
import { useRouter } from 'next/dist/client/router'
import env from 'utilities/env'
import { LINK_URL_ROOT } from '@freec/corelib/utilities/LinkURL'
import { LINK_URL_CANDIDATE_REGISTER, LINK_URL_FORGOT_PASSWORD, LINK_URL_CHAT_WITH_SUPPORT } from 'utilities/URL'
import AsyncAction from '@freec/corelib/components/share/AsyncAction'
import { LoginStyle, LoginGlobalStyle } from 'styles/components/styled/LoginStyle.style'
import { RESOURCE_IMAGE_FREEC_LOGO_EN, RESOURCE_IMAGE_FREEC_LOGO_VN } from 'utilities/Constant'
import {
  RESOURCE_IMAGE_DOWNLOAD_IOS,
  RESOURCE_IMAGE_DOWNLOAD_QRCODE,
  RESOURCE_IMAGE_DOWNLOAD_ANDROID
} from '@freec/corelib/utilities/Constant'
import IconFacebook from '../assets/icon_facebook.svg'
import IconGoogle from '../assets/icon_google.svg'
import IconLinkedin from '../assets/icon_linkedin.svg'
const LoginComponent: FC<{
  verifyData?: { email: string; user_id: number }
  nonFieldError?: string | ReactElement
  isVerify?: boolean
  loginIn?: boolean
  message?: string
  onFinish?: (arr1) => void
  onSendVerify?: (arr1, arr2) => void
  loginFacebook?: (arr1) => Promise<any>
  responseGoogle?: (arr1) => Promise<any>
  googleSocialErrorHandler?: (arr1) => void
  linkedInLogin: () => Promise<any>
}> = (props) => {
  const {
    verifyData,
    nonFieldError,
    isVerify,
    loginIn,
    message,
    onFinish,
    onSendVerify,
    loginFacebook,
    responseGoogle,
    googleSocialErrorHandler,
    linkedInLogin
  } = props
  const { translate, currentLanguage } = useTranslation()

  const router = useRouter()
  return (
    <>
      <style jsx>{LoginStyle}</style>
      <style jsx global>
        {LoginGlobalStyle}
      </style>
      <style jsx global>
        {SocialStyle}
      </style>
      <style jsx>{RegisterStyle}</style>
      <style jsx global>
        {ANTDInputStyle}
      </style>
      <div className="wrapper-container">
        <div className="login-page register-page">
          <Row justify="center">
            <Col xs={24} md={20}>
              <Row gutter={[{ xs: 0, lg: 16 }, 0]}>
                <Col xs={24} lg={14} xl={12}>
                  <div className="d-flex align-items-center justify-content-center flex-column register-form-wrapper">
                    <Row justify="center" className="w-100">
                      <Col xs={24} xl={20}>
                        <div className="logo">
                          <AppLink href={LINK_URL_ROOT}>
                            <a className="header-logo-img">
                              <Image
                                src={currentLanguage === 'en' ? RESOURCE_IMAGE_FREEC_LOGO_EN : RESOURCE_IMAGE_FREEC_LOGO_VN}
                                alt="freec-platform"
                                width={136}
                                height={46}
                              />
                            </a>
                          </AppLink>
                        </div>
                        <div className="login-form">
                          <Form name="login_form" layout="vertical" onFinish={onFinish}>
                            <div className="form-header d-flex flex-wrap w-100">
                              <div className="flex-1 active p-4">
                                <button type="button" className="btn-active">
                                  {translate('page_login:login')}
                                </button>
                              </div>
                              <div className="flex-1 p-4">
                                <button type="button" className="btn-inActive" onClick={() => router.push(LINK_URL_CANDIDATE_REGISTER)}>
                                  {translate('page_login:create_your_freec_account')}
                                </button>
                              </div>
                            </div>
                            <div className="xs:p-4 p-6">
                              <div>
                                {message && (
                                  <Row justify="center">
                                    <Col>
                                      <div className="message-notice">
                                        <ExclamationCircleFilled style={{ color: '#1890FF' }} />
                                        <span className="message-text mr-2">{message}</span>
                                        {isVerify && (
                                          <Button
                                            className="btn-resend_email"
                                            onClick={() => onSendVerify(verifyData.email, verifyData.user_id)}>
                                            {translate('page_login:resend_email')}
                                          </Button>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                              <Row gutter={[16, 8]}>
                                <Col xs={8} md={8} lg={8}>
                                  <AsyncAction
                                    action={() => linkedInLogin()}
                                    render={({ run, loading }) => (
                                      <Button className="btn-social" loading={loading} onClick={run}>
                                        <div className="icon-linkedin">
                                          <Image quality={100} src={IconLinkedin} alt="google icon" width={24} height={24} />
                                        </div>
                                      </Button>
                                    )}
                                  />
                                </Col>
                                <Col xs={8} md={8} lg={8}>
                                  <AsyncAction
                                    action={responseGoogle}
                                    render={({ run, loading }) => {
                                      return (
                                        <GoogleLogin
                                          clientId={env.PUBLIC_GOOGLE_APP_ID}
                                          render={(renderProps) => (
                                            <Button
                                              id={TRACKING_ID_LOGIN_GOOGLE}
                                              loading={loading}
                                              onClick={renderProps.onClick}
                                              disabled={loading || renderProps.disabled}
                                              className="btn-social"
                                              size="large">
                                              <div className="icon-facebook">
                                                <Image quality={100} src={IconGoogle} alt="google icon" width={24} height={24} />
                                              </div>
                                              {/* <span>{translate('page_login:sign_in_with_google')}</span> */}
                                            </Button>
                                          )}
                                          buttonText="Login"
                                          onSuccess={run}
                                          onFailure={googleSocialErrorHandler}
                                          cookiePolicy={'single_host_origin'}
                                        />
                                      )
                                    }}
                                  />
                                </Col>
                                <Col xs={8} md={8} lg={8}>
                                  <AsyncAction
                                    action={(response) => loginFacebook(response)}
                                    render={({ run, loading }) => {
                                      return (
                                        <FacebookProvider appId={env.PUBLIC_FACEBOOK_APP_ID}>
                                          <Login
                                            scope="email"
                                            onResponse={(response) => run(response)}
                                            render={({ isWorking, onClick }) => (
                                              <Button
                                                onClick={onClick}
                                                id={TRACKING_ID_LOGIN_GOOGLE}
                                                loading={isWorking || loading}
                                                className="btn-social"
                                                size="large">
                                                <div className="icon-facebook">
                                                  <Image src={IconFacebook} alt="facebook icon" width={24} height={24} />
                                                </div>
                                                {/* <span>{translate('page_login:sign_in_with_facebook')}</span> */}
                                              </Button>
                                            )}
                                          />
                                        </FacebookProvider>
                                      )
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Divider style={{ margin: `20px 0` }} plain className="divider-login">
                                {translate('page_login:or_login')}
                              </Divider>
                              <Form.Item
                                label={translate('label:email_address')}
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: translate('form:validate_required')
                                  },
                                  {
                                    max: 250,
                                    message: translate('form:validate_max_length', {
                                      number: 250
                                    })
                                  },
                                  {
                                    type: 'email',
                                    message: translate('form:validate_email_format')
                                  }
                                ]}>
                                <Input placeholder="email@example.com" size="large" maxLength={250} id="login_form_email" />
                              </Form.Item>
                              <Form.Item
                                label={translate('label:password')}
                                name="password"
                                rules={[
                                  {
                                    required: true,
                                    message: translate('form:validate_required')
                                  },
                                  {
                                    min: 6,
                                    message: translate('form:validate_range_length', {
                                      min: 6,
                                      max: 20
                                    })
                                  }
                                ]}>
                                <Input.Password
                                  size="large"
                                  placeholder={translate('label:placeholder_password')}
                                  id="login_form_password"
                                  maxLength={20}
                                />
                              </Form.Item>
                              <div className="error-text">{nonFieldError && <div>{nonFieldError}</div>}</div>
                              <AppLink href={LINK_URL_FORGOT_PASSWORD}>
                                <a className="link-forgot-pw" id="link_forgot_password">
                                  {translate('label:forgot_password')}
                                </a>
                              </AppLink>
                              <Form.Item>
                                <Button
                                  id={TRACKING_ID_LOGIN_LOGIN}
                                  loading={loginIn}
                                  type="primary"
                                  className="btn-submit w-100"
                                  htmlType="submit"
                                  size="large">
                                  {translate('button:login')}
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                    <Row justify="center" className="w-100 mt-3">
                      <Col xs={22} xl={20}>
                        <div className="d-flex xs:flex-col align-items-center justify-content-space-between">
                          <p className="text-bottom">
                            {translate('label:you_need_support')}
                            <AppLink href={LINK_URL_CHAT_WITH_SUPPORT}>
                              <a className="link-bottom" id="link_create_account">
                                {translate('label:contact_us_with_chat')}
                              </a>
                            </AppLink>
                          </p>
                          <ChangeLanguageDropdown className="xs:mt-4" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={0} lg={10} xl={12}>
                  <div className="d-flex flex-column block-right">
                    <p className="block-right_title">{translate('page_register:what_you_will_get')}</p>
                    <p className="detail">
                      <span className="icon mr-2">
                        <CheckCircleFilled />
                      </span>
                      <span>{translate('page_register:more_than_thousand_jobs')}</span>
                    </p>
                    <p className="detail">
                      <span className="icon mr-2">
                        <CheckCircleFilled />
                      </span>
                      <span>{translate('page_register:save_job_and_manage_application')}</span>
                    </p>
                    <Divider className="mt-0 mb-3" />
                    <p className="detail-text-small">{translate('label:download_our_mobile_app')}</p>
                    <p className="detail">{translate('page_register:never_miss_your_job')}</p>
                    <div className="d-flex">
                      <div className="img-apps">
                        <a href="https://apps.apple.com/us/app/freec/id1475879992?l=vi&ls=1" target="blank">
                          <div className="size-app">
                            <Image src={RESOURCE_IMAGE_DOWNLOAD_IOS} alt="code" width={135} height={40} />
                          </div>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.freec.asia" target="blank" className="mt-3">
                          <div className="size-app">
                            <Image src={RESOURCE_IMAGE_DOWNLOAD_ANDROID} alt="code" width={135} height={40} />
                          </div>
                        </a>
                      </div>
                      <div className="qr-code-view">
                        <div className="img-QR-code">
                          <Image src={RESOURCE_IMAGE_DOWNLOAD_QRCODE} alt="code" width={120} height={120} />
                        </div>
                        <span className="title">{translate('label:scan_to_download')}</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Footer />
        </div>
      </div>
    </>
  )
}

export default LoginComponent
